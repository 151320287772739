export const addToCookiesCart = (productID, number) => {
    let cart = getCookiesCart()
    if ((cart.filter(item => item.product === productID)).length) {
        cart = cart.map(item => {
            if (item.product === productID) {
                return { product: productID, number: (item.number + number) }
            }
            return item
        })
    } else {
        cart.push({ product: productID, number })
    }
    if(localStorage.getItem("allowCookies")) localStorage.setItem("cart", JSON.stringify(cart))
    return true
}
export const getCookiesCart = () => {
    let cart = JSON.parse(localStorage.getItem("cart"))
    if (!cart) cart = []
    return cart
}
export const patchNumberOfProductCookies = (id, number) => {
    let cart = getCookiesCart()
    if (number <= 0) cart = cart.filter(item => item.product !== id)
    else cart = cart.map(item => { return item.product === id ? { product: id, number } : item })
    if(localStorage.getItem("allowCookies")) localStorage.setItem("cart", JSON.stringify(cart))
}
export const resetCart = () => {
    if(localStorage.getItem("allowCookies")) localStorage.setItem("cart", JSON.stringify([]))
}