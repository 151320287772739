import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import Product from '../components/Product';
import backendAddress from "../scripts/backendAddress";

const ProductsArea = styled.div`
width: 80vw;
margin: auto;
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
max-width: 1200px;
`
const App = (props) => {
    const {buttonTrigger, triggerValue, category, searches} = props
    const [products, setProducts] = useState([])
    const productsToRender = products.filter(product =>
        (product.category === category || !category) &&
        (searches === product.name || product.tags.filter(tag => searches === tag).length > 0 || !searches)
    )
    useEffect(() => {
        axios.get(backendAddress + "/products/").then(res => {
            setProducts(res.data)
        })
    }, [])
    return (
        <ProductsArea>
                {
                    productsToRender.map((product, index) => (index < 3 ?
                        <Product product={product} key={product._id} buttonTrigger={buttonTrigger} triggerValue={triggerValue} />
                        :null
                        ))
                }
        </ProductsArea>
    );
};

export default App;