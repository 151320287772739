import Navbar from '../components/Navbar'
import { useFormik } from 'formik'
import { resetPassword } from "../router/user"
import { useState } from 'react'
import { ResponseLogBox, Title, SubmitInput, SignUpForm, BackGround, ButtonHolder, Comm, CommWrapper } from "../styles/signUp.jsx"
import InputText from '../components/InputText'
import ToShopButton from '../components/ToShopButton'
import Footer from '../components/Footer'
import {useNavigate} from "react-router-dom"
export const fields = [
    { key: "email", placeholder: "e-mail", type: "text" },
]
const Login = () => {
    
    const navigate = useNavigate()

    const [disabled, setDisabled] = useState(false)
    const [response, setResponse] = useState(null)
    if (response && !response.error) navigate("/message/" + encodeURI("wysłaliśmy na twój adres e-mail dalsze instrukcje"))
    const onSubmit = async (values, actions) => {
        setDisabled(true)
        setResponse(await resetPassword(values, actions))
        setDisabled(false)
    }
    const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit
    })
    return (
        <>
            <BackGround>
                <Navbar />
                <ButtonHolder>
                    <ToShopButton />
                </ButtonHolder>
                <SignUpForm onSubmit={handleSubmit}>
                    {response ? <ResponseLogBox style={response.error ? { color: "red" } : {}}>{response.log}</ResponseLogBox> : null}
                    <Title>Resetowanie hasła</Title>
                    <CommWrapper>
                        <Comm>Wprowadź adres email</Comm>
                    </CommWrapper>
                    {
                        fields.map(field => {
                            return (
                                <InputText
                                    key={field.key}
                                    field={field}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            )
                        })
                    }
                    <SubmitInput
                        type='submit'
                        value='resetuj hasło'
                        disabled={disabled}
                    />
                    
                </SignUpForm>
            </BackGround>
            <Footer/>
        </>
    )
}

export default Login