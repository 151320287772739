
import { useEffect, useState } from 'react'
import { SearchBar, SearchBarInput, TagsView, TagView, ShortcutIconSearch } from "../styles/menu.jsx"
import getTags from '../controller/getTags.js'
import { Link, useParams, useNavigate } from 'react-router-dom'
import backendAddress from "../scripts/backendAddress";
const Searcher = (props) => {
    const [focusSearcher, setFocusSearcher] = useState(false)
    const navigate = useNavigate()
    const detailsJSON = (useParams()).details
    const { searches, filters, category } = detailsJSON ? JSON.parse(detailsJSON) : {}
    const [searchBarValue, setSearchBarValue] = useState(searches)
    const [tags, setTags] = useState([])
    const [mouseOnTags, setMouseOnTags] = useState(false)
    const loadTags = async () => {
        setTags(await getTags())
    }
    const matches = tags.filter(tag => tag.match(new RegExp(searchBarValue)))
    useEffect(() => {
        loadTags()
    }, [])
    const searchImg = backendAddress + "/app//src//static//searchIcon.png"
    return (
        <SearchBar>
            <ShortcutIconSearch src={searchImg}/>
            <form onSubmit={(e) => {
                e.preventDefault();
                if (!searchBarValue) navigate(`/shop/${JSON.stringify({ category, filters })}`)
            }}>
                <SearchBarInput
                    type={"text"}
                    value={searchBarValue}
                    onChange={e => { setSearchBarValue(e.target.value) }}
                    onFocus={() => setFocusSearcher(true)}
                    onBlur={() => setFocusSearcher(false)}
                ></SearchBarInput>
            </form>
            {
                (focusSearcher || mouseOnTags) && searchBarValue && matches.length ?
                    <TagsView onMouseLeave={() => setMouseOnTags(false)} onMouseEnter={() => setMouseOnTags(true)}>
                        {
                            matches.map((match, index) =>
                                <TagView key={index} to={`/shop/${JSON.stringify({ category, filters, searches: match })}`} >
                                    {match}
                                </TagView>)
                        }
                    </TagsView>
                    : null
            }
        </SearchBar>
    )
}
export default Searcher