import * as yup from "yup"
const passRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/
const phoneRules = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/
export const signUpSchema = yup.object().shape({
    name: yup
        .string()
        .max(500, "zbyt długie"),
    surname: yup
        .string()
        .max(500, "zbyt długie"),
    email: yup
        .string()
        .email("proszę podać poprawny e-mail")
        .required("to pole jest wymagane").max(50, "zbyt zługie"),
    password: yup
        .string()
        .matches(passRules, { message: "hasło musi mieć przynajmniej 6 liter, zawierać duże, małe litery oraz cyfry" })
        .required("to pole jest wymagane")
        .max(50, "zbyt długie"),
    passwordChk: yup
        .string()
        .oneOf([yup.ref("password"), null], "hasła nie są takie same")
        .required("to pole jest wymagane"),
    phone: yup
        .string()
        .matches(phoneRules, { message: "niepoprawny numer telefonu" }),
    country: yup
        .string()
        .max(50, "zbyt długie"),
    city: yup
        .string()
        .max(50, "zbyt długie"),
    street: yup
        .string()
        .max(50, "zbyt długie"),
    zipCode: yup
        .string()
        .max(50, "zbyt długie"),
    houseNumber: yup
        .string()
        .max(50, "zbyt długie"),
    other: yup
        .string()
        .max(500, "zbyt długie"),
})
