import { ErrorText, UnderlinedInput } from "../styles/signUp.jsx"
const InputText = (props) => {
    const { field, errors, touched, values, handleBlur, handleChange } = props
    return (
        <>
            <UnderlinedInput
                style={errors[field.key] && touched[field.key] ? { borderColor: "red" } : {}}
                name={field.key}
                type={field.type}
                placeholder={field.placeholder}
                value={values[field.key]}
                onBlur={handleBlur}
                onChange={handleChange}
            />
            {errors[field.key] && touched[field.key] ? <ErrorText>{errors[field.key]}</ErrorText> : null}
            <br />
        </>
    )
}
export default InputText