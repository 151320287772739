import { Link } from "react-router-dom"
import styled from "styled-components"
export const Holder = styled.div`
        overflow-y: auto;
        overflow-x: hidden;
        white-space: nowrap;
        max-height: 300px;
        width: 330px;
        padding: 10px;
        background-color: rgb(248, 246, 244);
        border: solid rgb(180, 171, 165) 1px;
        /* border-radius: 5px; */
        position: absolute;
        margin-left: -275px;
        color: rgb(158, 143, 122);
        text-align: center;
        z-index: 2;
        @media (max-width: 850px){
            left: 50%;
            margin-left: 0;
            transform: translate(-50%, 0);
        }
    `
export const CloseIcon = styled.img`
    padding: 5px;
    opacity: 0.5;
    height: 25px;
`
export const CloseHolder = styled.div`
    display: flex;
    justify-content: end;
`
export const Payer = styled(Link)`
    text-decoration: ${elem => elem.isOk ? "none" : "line-through"};
    padding-top: 10px;
    display: block;
    width: 100%;
    font-size: 20px;
    /* border-top: solid rgb(180, 171, 165) 1px; */
    color: rgb(158, 143, 122);
    text-align: center;
    cursor: pointer;
    transition: 300ms;
    &:hover ${this} {
    padding-top: 10px;
    color: rgba(158, 143, 122, 0.7);
    }
    `