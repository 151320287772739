import * as yup from "yup"
const phoneRules = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/
export const shippingSchema = yup.object().shape({
    name: yup
        .string()
        .max(500, "zbyt długie")
        .required("to pole jest wymagane")
        .max(50, "zbyt zługie"),
    surname: yup
        .string()
        .max(500, "zbyt długie")
        .required("to pole jest wymagane")
        .max(50, "zbyt zługie"),
    email: yup
        .string()
        .email("proszę podać poprawny e-mail")
        .required("to pole jest wymagane")
        .max(50, "zbyt zługie"),
    phone: yup
        .string()
        .matches(phoneRules, { message: "niepoprawny numer telefonu" })
        .required("to pole jest wymagane")
        ,
    country: yup
        .string()
        .max(50, "zbyt długie")
        .required("to pole jest wymagane")
        ,
    city: yup
        .string()
        .max(50, "zbyt długie")
        .required("to pole jest wymagane")
        ,
    street: yup
        .string()
        .max(50, "zbyt długie")
        // .required("to pole jest wymagane")
        ,
    zipCode: yup
        .string()
        .max(50, "zbyt długie")
        .required("to pole jest wymagane")
        ,
    houseNumber: yup
        .string()
        .max(50, "zbyt długie")
        .required("to pole jest wymagane")
        ,
    other: yup 
        .string()
        .max(500, "zbyt długie"),
    nip_number: yup
        .number().typeError("niepoprawny format - NIP musi być liczbą")
        .max(9999999999, "zbyt długie")
        .min(1000000000, "zbyt krótkie"),
    company_name: yup
        .string()
        .max(50, "zbyt długie"),
    country_invoice: yup
        .string()
        .max(50, "zbyt długie"),
    city_invoice: yup
        .string()
        .max(50, "zbyt długie"),
    street_invoice: yup
        .string()
        .max(50, "zbyt długie"),
    zipCode_invoice: yup
        .string()
        .max(50, "zbyt długie"),
    houseNumber_invoice: yup
        .string()
        .max(50, "zbyt długie"),
})