import React from 'react'
import { useState } from 'react';
import MobileMenu from './MobileMenu.jsx';
import MobileCartShortcut from './MobileCartShortcut.jsx';
import MobileAccountShortcut from './MobileAccountShortcut.jsx';
import MobileMenuShortcut from './MobileMenuShortcut.jsx';
import MobileAccountMenu from "./MobileAccountMenu.jsx"
import {Wrapper, Holder } from "../styles/navbar.jsx"
import { Link } from 'react-router-dom';
const Navbar = (props) => {
    const {cartView, setCartView, user, setUser, addToCartTrigger} = props
    const [mobileMenuType, setMobileMenu] = useState("")
    const openMenu = (type) => {
        if(mobileMenuType === type)setMobileMenu("")
        else setMobileMenu(type)
    }
    return (
        
                <Wrapper>
                    <Holder>
                    <MobileCartShortcut
                        addToCartTrigger={addToCartTrigger} 
                        user = {user}
                        setUser = {setUser}
                        cartView = {cartView} 
                        setCartView = {setCartView}
                        mobileMenuType={mobileMenuType}
                        openMenu = {openMenu}
                    />
                    <MobileAccountShortcut
                        user={user}
                        mobileMenuType={mobileMenuType}
                        openMenu = {openMenu}
                    />
                    <MobileMenuShortcut
                        mobileMenuType={mobileMenuType}
                        openMenu = {openMenu}
                    />
                    </Holder>
                    {mobileMenuType === "categories" ? <MobileMenu/> : null}
                    {mobileMenuType === "account" ? <MobileAccountMenu/> : null}
                    {/* {mobileMenuType === "cart" ? <MobileAccountMenu/> : null} */}
                </Wrapper>
    )
}

export default Navbar