
import { useParams } from "react-router-dom"
import { getItem } from "../router/products"
import { useEffect, useState } from "react"
import ProductsForm from "./ProductsForm"
import ChangeImages from "./ChangeImages"
import ChangeTags from "./ChangeTags"
import Navbar from "./Navbar"
const ProductsPatch = () => {
    const { id } = useParams()
    const [product, setProduct] = useState(null)
    const getProduct = async (_id) => {
        if (_id === "add") return
        const res = await getItem(_id)
        setProduct(res.data)
    }
    useEffect(() => { getProduct(id) }, [])
    return <div>
        <Navbar />
        {product || id === "add" ?
            <div>
                {product ? <ChangeImages product={product} getProduct={getProduct} /> : null}
                {product ? <ChangeTags product={product} getProduct={getProduct} /> : null}
                <ProductsForm product={product} getProduct={getProduct} />
            </div> : <div>Loading...</div>
        }
    </div>
}
export default ProductsPatch