

import { getToken, saveToken } from "../scripts/storage"
import { Holder, OptionHolder, LinkText } from "../styles/accountMenu.jsx"
import {useNavigate} from "react-router-dom"
const App = (props) => {
    const navigate = useNavigate()
    const token = getToken()
    const {setMouseOnMenu, setAccountFocus, logOut, setLogOut} = props
    if (!token) return (
        <Holder
        onMouseEnter={()=>setMouseOnMenu(true)}
        onMouseLeave={()=>setMouseOnMenu(false)}
        >
            <LinkText 
            to="/Login"
            onMouseUp={()=>{setTimeout(()=>{setMouseOnMenu(false);setAccountFocus(false)})}}
            >
                <OptionHolder>
                    zaloguj się
                </OptionHolder>
            </LinkText>
            <LinkText to="/SignUp">
                <OptionHolder>
                    zarejestruj się
                </OptionHolder>
            </LinkText>
        </Holder>
    );
    return (
        <Holder
        >
            <OptionHolder
                onMouseUp={() => {
                    saveToken("")
                    setLogOut(!logOut)
                    navigate("/message/wylogowano")
                }}
            >
                wyloguj się
            </OptionHolder>
        </Holder>
    )
};
export default App;