import styled from "styled-components"
import { KeyboardArrowLeft } from '@material-ui/icons'
import { Link } from 'react-router-dom'

const Wrapper = styled.p`
    z-index: 2;
    color: rgb(180, 171, 165);
    font-size: 35px;
    padding: 10px;
    border: solid rgb(180, 171, 165) 1px;
    font-size: 20px;
    float: left;
    width: 120px;
    border-radius: 10px;
    margin: 5px;
    cursor: pointer;
    transition: 300ms;
    background-color: white;
    :hover${this}{
        padding-right: 20px;
        background-color: rgb(248, 246, 244);
        padding-left: 5px;
        padding-right: 15px;
    }
`

const Arrow = styled(KeyboardArrowLeft)`
background-color: white;
color: rgb(206, 194, 181);
border-radius: 100%;
font-size: 10px;
margin-right: 10px;
margin-top: 3px;
margin-bottom: -3px;
`
const ToShopButton = () => {
    return (
        <Link to="/">
            <Wrapper>
                <Arrow style={{fontSize: "20px"}} />
                do sklepu
            </Wrapper>
        </Link>
    )
}
export default ToShopButton