import { Holder, Payer, CloseIcon, CloseHolder } from "../styles/cart.jsx"
import ProductInCart from "../components/ProductInCart";
import backendAddress from "../scripts/backendAddress";
const App = (props) => {
    const { setCartView, isClosable, cart, setChangeNumberTrigger, changNumberTrigger, isEmpty, setMouseOnCart, cartFocus } = props
    const totalPrice = cart.length > 0 ? cart.map(item => item.name ? item.price * item.number : 0).reduce((total, item) => total + item) : 0
    let isEveryNumberOk = true 
    cart.forEach(elem => {isEveryNumberOk = isEveryNumberOk && (!elem.name || elem.number <= elem.amount)});
    return (
        <Holder
            onMouseEnter = {() => {if(setMouseOnCart)setMouseOnCart(true)}}
            onMouseLeave = {() => {if(setMouseOnCart)setMouseOnCart(false)}}
        >   
            {isClosable ? <CloseHolder><CloseIcon 
                src={backendAddress + `/app//src//static//cancel.png`}
                onClick={() => {if(setCartView)setCartView(false)}}
            /></CloseHolder> : null}
            {isEmpty ? "koszyk pusty" :
                cart.map(product => {
                    if (product.name) 
                    return(
                    <ProductInCart
                        key={product._id}
                        number={product.number}
                        name={product.name}
                        photo={product.photo}
                        price={product.price}
                        id={product._id}
                        amount={product.amount}
                        setChangeNumberTrigger={setChangeNumberTrigger}
                        changNumberTrigger={changNumberTrigger}
                    />)
                    // else 
                })
            }
            {
                !isEmpty ?
                    <Payer 
                        isOk={isEveryNumberOk}
                        // onMouseUp = {() => {if(setMouseOnCart)setTimeout(()=>{setMouseOnCart(false);setCartFocus(false)})}}
                        to={isEveryNumberOk ? 
                            `/shipping/${JSON.stringify(cart.filter(elem => elem.name).map(elem => ({ number: elem.number, id: elem._id })))}`:
                            `/message/${encodeURI("nie posiadamy tak dużej ilości produktów w magazynie, zmniejsz ilość produktów w koszyku")}`
                        }
                    >
                        zamów: {totalPrice.toFixed(2)} zł
                    </Payer>
                    : null
            }
        </Holder >
    );
};
export default App;