
import backendAddress from "../scripts/backendAddress";
import { ShortcutHolder, ShortcutIcon, Notification } from "../styles/menu.jsx"
const AccountShortcut = (props) => {
    const {user, openMenu, mobileMenuType} = props
    const userImg = backendAddress + 
    `/app//src//static//${mobileMenuType === "account" ? "cancel" : "userIcon"}.png`
    return (
        <ShortcutHolder
            onClick = {() => {openMenu("account")}}
        >
            {user ? <Notification color="green">{user.name[0]}{user.surname[0]}</Notification> : null}
            <ShortcutIcon src={userImg}/>
            
        </ShortcutHolder>
    )
}
export default AccountShortcut