import { saveToken, getToken } from "../scripts/storage"
import axios from "axios"
import backendAddress from "../scripts/backendAddress";
export const signUpReq = async (values, actions) => {
    const options = {
        method: 'POST',
        url: backendAddress + "/users/register",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
        data: values,
    };

    try {
        let response = await axios.request(options)
        if (!response.error) actions.resetForm()
        return { log: "użytkownik został zarejestrowany", error: false }
    } catch (error) {
        console.log(error)
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else
            return { log: "użytkownik z podanym e-mailem istnieje", error: true }
    }
}

export const loginReq = async (values, actions) => {
    const options = {
        method: 'POST',
        url: backendAddress + "/users/login",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
        data: values,
    };

    try {
        let response = await axios.request(options)
        saveToken(response.data);
        if (!response.error) actions.resetForm()
        return { log: "zalogowano", error: false }
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else
            return { log: "niepoprawne dane logowania", error: true }
    }
}

export const resetPassword = async (values, actions) => {
    const options = {
        method: 'POST',
        url: backendAddress + "/users/resetPassword",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
        data: values,
    };

    try {
        let response = await axios.request(options)
        saveToken(response.data);
        if (!response.error) actions.resetForm()
        return { log: "zalogowano", error: false }
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else
            return { log: "niepoprawny e-mail", error: true }
    }
}
export const confirmAccount = async (email, token) => {
    const options = {
        method: 'POST',
        url: backendAddress + "/users/confirmAccount",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
        data: {email, token},
    };

    try {
        let response = await axios.request(options)
        saveToken(response.data.token);
        return { log: "zalogowano", error: false }
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else
            return { log: "niepoprawny e-mail", error: true }
    }
}
export const setNewPassword = async (values, actions) => {
    const options = {
        method: 'POST',
        url: backendAddress + "/users/setNewPassword",
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
        data: values,
    };

    try {
        let response = await axios.request(options)
        saveToken(response.data);
        if (!response.error) actions.resetForm()
        return { log: "zalogowano", error: false }
    } catch (error) {
        if (error.code === "ERR_NETWORK")
            return { log: "błąd sieci", error: true }
        else
            return { log: "Token wygasł", error: true }
    }
}

export const getUser = async (productID) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'GET',
            url: backendAddress + "/users/myData",
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
        };

        try {
            let response = await axios.request(options)
            resolve(response.data);
        } catch (error) {
            reject(error)
        }
    })
}