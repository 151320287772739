import styled from "styled-components"
export const Input = styled.input`
    width: 500px;
    margin-bottom: 20px;
`
export const Form = styled.form`
    margin: auto;
    width: min-content;
`
export const Submit = styled.input`
    float: right;
`

export const ErrorLog = styled.div`
    font-size: 15px;
    color: red;
`
