import Navbar from '../components/Navbar'
import { BackGround, Title, Card, Text } from "../styles/account.jsx"
import Footer from '../components/Footer'
import { ButtonHolder  } from "../styles/signUp.jsx"
import ToShopButton from '../components/ToShopButton'
const Returns = () => {

    return(
        <>
            <BackGround>
                <Navbar />
                <ButtonHolder>
                    <ToShopButton />
                </ButtonHolder>
                <Card>
                    <Title>Reklamacje i zwroty</Title><br />
                    <Text>
                    Klient ma prawo do reklamacji, którą powinien złożyć drogą mailową na e-mail: pracowniaiskry@gmail.com. Sprzedawca rozpatrzy reklamację i ustosunkuje się do niej w ciągu 14 dni. W mailu Klient powinien dostarczyć datę zamówienia, nr zamówienia i informację o reklamowanym produkcie (powód reklamacji, opis wady, uczulenia, ewentualne zdjęcia, inne)<br />
                    W przypadku pozytywnego rozpatrzenia reklamacji Klient powinien przesłać wadliwy towar wraz z paragonem na adres: 32-445 Krzyszkowice 629, pracownia iskry Piotr Iskra wraz z numerem konta do zwrotu pieniędzy lub adres do wysyłki nowego produktu (w przypadku wymiany produktu). Sprzedawca zastrzega sobie, że zwrócony towar powinien mieć zużyte nie więcej niż 1/4 produktu.<br />
                    <br />
                    Odstąpienie od umowy<br />
                    <br />
                    Klient ma prawo zrezygnować z zakupionego towaru. Towar nie powinien być uszkodzony i nie powinien nosić śladów użytkowania.<br />
                    Klient ma prawo odstąpić od umowy, bez podania przyczyny, w terminie 14 dni od dnia dostarczenia Produktu. Chęć zwrotu należy przesłać drogą mailową na adres pracowniaiskry@gmail.com  wraz z Formularzem odstąpienia od umowy.<br />
                    Sprzedawca w ciągu 7 dni roboczych potwierdzi przyjęcie towaru.<br />
                    Klient wyśle produkty wraz z paragonem na adres pracowni: 32-445 Krzyszkowice 629, pracownia iskry Piotr Iskra na własny koszt.<br />
                    <br />
                    Ponowna wysyłka nieodebranej przez Klienta paczki<br />
                    <br />
                    W przypadku, gdy Klient nie odbierze paczki, zamówiony towar wraca do Sprzedawcy (Sprzedawca ponosi koszt powrotu przesyłki i nie żąda od Klienta zwrotu tego kosztu).<br />
                    Ponowna wysyłka jest możliwa po kontakcie ze Sprzedawcą drogą mailową i wpłacie przez Klienta na konto Sprzedawcy (dane konta podane poniżej) opłaty za ponowną wysyłkę - 15 zł. <br />
                    Klient powinien powiadomić mailowo Sprzedawcę o wpłacie kwoty 15 zł za ponowną wysyłkę lub przesłać potwierdzenie przelewu na adres pracowniaiskry@gmail.com <br />
                    Nr konta: 13 1160 2202 0000 0005 7238 3020 
                    </Text>
                </Card>
            </BackGround>
            <Footer/>
        </>
    )
}
export default Returns