import { getItems } from "../router/products"

const getTags = () => new Promise(
    async (resolve, reject) => {
        const tags = (await getItems()).data
            .map(item => [...item.tags, item.name])
            .reduce((accumulator, currentValue) => [...accumulator, ...currentValue])
        const uniqueTags = tags.filter((item, pos) => tags.indexOf(item) === pos)
        resolve(uniqueTags)
    }
)
export default getTags