import styled from "styled-components"
import { Link } from "react-router-dom"

export const Container = styled.div`
    background-color: rgb(211, 206, 200);
    padding: 10px;
`
export const SectionTitle = styled.div`
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
`
export const Reference = styled(Link)`
    color: black;
    text-decoration: none;
    padding: 10px;
    line-height: 40px;
    padding-left: 20px;
    font-size: 18px;
    transition: 300ms;
    :hover{
        opacity: 0.5;
    }
`
export const AReference = styled.a`
    color: black;
    text-decoration: none;
    padding: 10px;
    line-height: 40px;
    padding-left: 20px;
    font-size: 18px;
    transition: 300ms;
    :hover{
        opacity: 0.5;
    }
`
export const AReferenceUnderline = styled.a`
    color: black;
    /* text-decoration: none; */
    /* padding: 10px; */
    line-height: 40px;
    /* padding-left: 20px; */
    /* font-size: 18px; */
    transition: 300ms;
    /* text-align: bol; */
    /* font-weight: bold; */
    :hover{
        opacity: 0.5;
    }
`
export const Right = styled.p`
    float: right;
`