
import AccountMenu from './AccountMenu';
import { ShortcutHolder, ShortcutIcon, Notification } from "../styles/menu.jsx"
import backendAddress from "../scripts/backendAddress";
const AccountShortcut = (props) => {
    const {mouseOnMenu, setMouseOnMenu, accountFocus, setAccountFocus, user, logOut, setLogOut} = props
    const userImg = backendAddress + "/app//src//static//userIcon.png"
    
    return (
        <ShortcutHolder
        focus={accountFocus}
        onFocus={()=>{setAccountFocus(true)}}
        onBlur={()=>{if(!mouseOnMenu)setAccountFocus(false)}}
        >
            {user ? <Notification color="green">{user.name[0]}{user.surname[0]}</Notification> : null}
            <ShortcutIcon src={userImg}/>
            {accountFocus ? <AccountMenu 
            setLogOut={setLogOut}
            logOut={logOut}
            setMouseOnMenu={setMouseOnMenu} 
            setAccountFocus={setAccountFocus}
            email={user ? user.email : null}
            ></AccountMenu> : null}
        </ShortcutHolder>
    )
}
export default AccountShortcut