import Navbar from '../components/Navbar'
import { BackGroundShort, ButtonHolder, Header, Holder, Button } from "../styles/signUp.jsx"
import ToShopButton from '../components/ToShopButton'
import Footer from '../components/Footer'
import {useNavigate, useParams} from "react-router-dom"
import { confirmAccount } from '../router/user.js'
const Login = () => {
    
    const navigate = useNavigate()

    const {email, token} = useParams()

    const confirmButtonClick = async() => {
        const resp = await confirmAccount(email, token)
        if (resp.error) navigate("/message/" + encodeURI("wystąpił błąd"))
        else navigate("/message/" + encodeURI("konto zostało utworzone poprawnie, aby z niego korzystać zaloguj się"))
    }
    
    return (
        <>
            <BackGroundShort>
                <Navbar />
                <ButtonHolder>
                    <ToShopButton />
                </ButtonHolder>
                <Holder>
                    <Header>Witaj {email}!</Header>
                    <Button onClick = {confirmButtonClick}>Potwierdź rejestrację</Button>
                </Holder>
            </BackGroundShort>
            <Footer/>
        </>
    )
}

export default Login