import Navbar from '../components/Navbar'
import { useFormik } from 'formik'
import { signUp as fields } from "../scripts/formsData"
import { signUpSchema } from "../scripts/signUpSchema"
import { signUpReq } from "../router/user"
import { useState } from 'react'
import { ButtonHolder, ResponseLogBox, Title, SubmitInput, SignUpForm, BackGround, Comm, CommWrapper } from "../styles/signUp.jsx"
import { Radio, ShipMethod, TextHolder, A, Center } from '../styles/shipping'
import InputText from '../components/InputText'
import ToShopButton from "../components/ToShopButton.jsx"
import {useNavigate} from "react-router-dom"
import Footer from '../components/Footer.jsx'
import {LinkText } from '../styles/shipping'
import backendAddress from '../scripts/backendAddress.js'
const SignUp = () => {
    const [accepted, setAccept] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [response, setResponse] = useState(null)
    const navigate = useNavigate()
    if(response && !response.error) navigate("/message/" + encodeURI("sprawdź swoją skrzynkę pocztową aby potwierdzić rejestrację"))
    const onSubmit = async (values, actions) => {
        setDisabled(true)
        setResponse(await signUpReq(values, actions))
        setDisabled(false)
    }
    const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
        initialValues: {
            name: "",
            surname: "",
            email: "",
            password: "",
            passwordChk: "",
            phone: "",
            country: "",
            city: "",
            street: "",
            zipCode: "",
            houseNumber: "",
            other: "",
        },
        validationSchema: signUpSchema,
        onSubmit
    })
    return (
        <BackGround>
            <Navbar />
            <ButtonHolder>
                <ToShopButton />
            </ButtonHolder> 
            <CommWrapper>
                        <Comm>•Jeżeli posiadasz już konto, <LinkText to="/Login">kliknij aby się zalogować</LinkText></Comm>
                       

                </CommWrapper>
            <SignUpForm onSubmit={handleSubmit}>
                <Title>Rejestracja</Title>
                <Center>
                {
                    fields.map(field => {
                        return (
                            <InputText
                                key={field.key}
                                field={field}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                            />
                        )
                    })
                }
                </Center>
                {response ? <ResponseLogBox style={response.error ? { color: "red" } : {}}>{response.log}</ResponseLogBox> : null}
                <TextHolder>
                    <Radio type="checkbox" id="accept" checked={accepted} onClick={()=>{setAccept(!accepted)}}/>
                    <ShipMethod for="accept">Przeczytałem/am i akceptuję <A href={`${backendAddress}/app//src//static//regulamin.pdf`}>regulamin sprzedaży</A> i <A href={`${backendAddress}/app//src//static//rodo.pdf`}>politykę prywatności</A></ShipMethod>
                </TextHolder>
                <SubmitInput
                    type='submit'
                    value='zarejestruj się' 
                    disabled={disabled || !accepted}
                />
                 <br />
                        <br />
               
            </SignUpForm>
            <Footer/>
        </BackGround>
    )
}

export default SignUp