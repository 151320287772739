import Navbar from '../components/Navbar'
import * as yup from "yup"
import { useFormik } from 'formik'
import { setNewPassword } from "../router/user"
import { useState } from 'react'
import { ResponseLogBox, Title, SubmitInput, SignUpForm, BackGround, ButtonHolder, Comm, CommWrapper } from "../styles/signUp.jsx"
import InputText from '../components/InputText'
import ToShopButton from '../components/ToShopButton'
import Footer from '../components/Footer'
import {useNavigate, useParams} from "react-router-dom"
const fields = [
    { key: "password", placeholder: "hasło", type: "password" },
    { key: "passwordChk", placeholder: "potwierdź hasło", type: "password" },
]
const validationSchema = yup.object().shape({
    password: yup
        .string()
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/, { message: "hasło musi mieć przynajmniej 6 liter, zawierać duże, małe litery oraz cyfry" })
        .required("to pole jest wymagane")
        .max(50, "zbyt długie"),
    passwordChk: yup
        .string()
        .oneOf([yup.ref("password"), null], "hasła nie są takie same")
        .required("to pole jest wymagane")
})
const Login = () => {
    
    const navigate = useNavigate()

    const {email, token} = useParams()

    const [disabled, setDisabled] = useState(false)
    const [response, setResponse] = useState(null)
    if (response && !response.error) navigate("/message/" + encodeURI("hasło zostało zaktualizowane"))
    const onSubmit = async (values, actions) => {
        setDisabled(true)
        setResponse(await setNewPassword({...values, email, token}, actions))
        setDisabled(false)
    }
    const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
        initialValues: {
            password: "",
            passwordChk: "",
        },
        validationSchema,
        onSubmit
    })
    return (
        <>
            <BackGround>
                <Navbar />
                <ButtonHolder>
                    <ToShopButton />
                </ButtonHolder>
                <SignUpForm onSubmit={handleSubmit}>
                    {response ? <ResponseLogBox style={response.error ? { color: "red" } : {}}>{response.log}</ResponseLogBox> : null}
                    <Title>Wprowadź nowe hasło</Title>
                    <CommWrapper>
                        <Comm>dla użytkownika {email}</Comm>
                    </CommWrapper>
                    {
                        fields.map(field => {
                            return (
                                <InputText
                                    key={field.key}
                                    field={field}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            )
                        })
                    }
                    <SubmitInput
                        type='submit'
                        value='resetuj hasło'
                        disabled={disabled}
                    />
                    
                </SignUpForm>
            </BackGround>
            <Footer/>
        </>
    )
}

export default Login