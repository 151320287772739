import styled from "styled-components"
export const Td = styled.td`
    padding-top: 20px;
    margin: 0;
    font-size: 22px;
`
export const Th = styled.th`
    font-size: 22px;
    padding-left: 20px;
    padding-right: 20px;
`
export const Tr = styled.tr`
    border-top: solid black 1px;
`
export const TdMin = styled.td`
    padding-top: 10px;
    margin: 0;
    font-size: 18px;
`
export const ThMin = styled.th`
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
`
export const TrMin = styled.tr`
    border-top: solid black 1px;
`
export const Table = styled.table`
    margin: auto;
`
export const Holder = styled.div`
    display: flex;
    flex-direction: row;
    width: max-content;
    padding: 10px;
    background-color: #f8f7f7;
    box-shadow: 2px 2px 2px 1px rgba(49, 49, 49, 0.2);
    margin: auto;
`

export const TextPlace = styled.div`
    font-size: 18px;
    width: 50%;
`
export const InputNumber = styled.input`
    width: 75px;
    font-size: 22px;
`
export const addLink = { display: "block", width: "100%", height: "100%", cursor: "pointer", color: "black" }

export const ButtonOpen = styled.button`
    margin-right: 30px;
    font-size: 15px;
`