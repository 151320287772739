import { Link } from "react-router-dom"
import styled from "styled-components"
import {MobileMenuWrapper} from "../styles/menu"
const Container = styled.div`
    border-top: solid black 1px;
    background-color: rgb(211, 206, 200);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
`
const LinkText = styled(Link)`
color: black;
text-decoration: none;
transition: 200ms;
font-size: 25px;
padding: 5px 20px;
text-align: center;
&:hover ${this} {
padding-right: 15px;
padding-left: 25px;
}
`
const MobileMenu = (props) => {
    return (
        <MobileMenuWrapper>
            <Container
            >
                <LinkText to="/login">zaloguj się</LinkText>
                <LinkText to="/signup">zarejestruj się</LinkText>
            </Container>
        </MobileMenuWrapper>
    )
}
export default MobileMenu