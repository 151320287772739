
import backendAddress from "../scripts/backendAddress";
import { ShortcutIcon, ShortcutHolder, MobileMenuWrapper } from "../styles/menu.jsx"
const CartShortcut = (props) => {
    const {mobileMenuType, openMenu } = props
    const menuImg = backendAddress + 
    `/app//src//static//${mobileMenuType === "categories" ? "cancel" : "menu"}.png`
    return (
        <MobileMenuWrapper>
            <ShortcutHolder
                onClick={() => {
                    openMenu("categories")
                    }}
            >
                <ShortcutIcon src={menuImg}/>
            </ShortcutHolder>
        </MobileMenuWrapper>
    )
}
export default CartShortcut