import Categories from "./Categories"
import Searcher from "./Searcher"
import styled from "styled-components"
import {MobileMenuWrapper} from "../styles/menu"
const Container = styled.div`
    border-top: solid black 1px;
    background-color: rgb(211, 206, 200);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
`
const MobileMenu = (props) => {
    return (
        <MobileMenuWrapper>
            <Container>
                {/* <Searcher/> */}
                <Categories/>
            </Container>
        </MobileMenuWrapper>
    )
}
export default MobileMenu