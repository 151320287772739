
import { ProductHolder, ProductsHolder, Image, Td, Th, Wrapper, BigLinkText } from '../styles/shipping'

import backendAddress from "../scripts/backendAddress";
const ItemsView = (props) => {
    const {itemsToShow} = props
    return (
        <Wrapper>
            
            <ProductsHolder>
                <tr>
                    <Th>zdjęcie</Th>
                    <Th>nazwa</Th>
                    <Th>ilość</Th>
                    <Th>cena</Th>
                </tr>
                {itemsToShow.map(item => (
                    <ProductHolder key={item._id}>
                        <Td><Image src={backendAddress + "/" + item.photo.replaceAll("\\", "//")} /></Td>
                        <Td><BigLinkText>{item.name}</BigLinkText></Td>
                        <Td>{item.number}</Td>
                        <Td>{(item.price * item.number).toFixed(2)}zł</Td>
                    </ProductHolder>
                ))}
            </ProductsHolder>
        </Wrapper>
    )
}
export default ItemsView