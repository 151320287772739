import { getMyOrders } from "../router/orders"
import { useEffect, useState } from "react"
import Navbar from '../components/Navbar'
import { BackGround, Title } from "../styles/account.jsx"
import Footer from '../components/Footer'
import OrderView from '../components/OrderView'
import { useNavigate } from 'react-router-dom'

const Account = () => {
    const [orders, setOrders] = useState(null)

    const navigate = useNavigate()
    useEffect(async()=>{
        setOrders(await getMyOrders(()=>navigate("/login")))
    }, [])

    if(orders)return(
        <>
            <BackGround>
                <Navbar />
                <Title>Zamówienia</Title>
                {orders.map((order)=><OrderView order={order}/>)}
            </BackGround>
            <Footer/>
        </>
    )
    return(<>Loading...</>)
}
export default Account