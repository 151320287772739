export const signUp = [
    { key: "email", placeholder: "e-mail", type: "email" },
    { key: "password", placeholder: "hasło", type: "password" },
    { key: "passwordChk", placeholder: "potwierdź hasło", type: "password" },
    { key: "name", placeholder: "imię", type: "text" },
    { key: "surname", placeholder: "nazwisko", type: "text" },
    { key: "phone", placeholder: "numer telefonu", type: "text" },
    { key: "country", placeholder: "kraj", type: "text" },
    { key: "city", placeholder: "miasto", type: "text" },
    { key: "street", placeholder: "ulica", type: "text" },
    { key: "zipCode", placeholder: "kod pocztowy", type: "text" },
    { key: "houseNumber", placeholder: "numer domu", type: "text" },
    { key: "other", placeholder: "dodatkowe informacje do wysyłki", type: "text" },
]
export const shipping = [
    { key: "name", placeholder: "imię", type: "text" },
    { key: "surname", placeholder: "nazwisko", type: "text" },
    { key: "email", placeholder: "e-mail", type: "email" },
    { key: "phone", placeholder: "numer telefonu", type: "text" },
    { key: "country", placeholder: "kraj", type: "text" },
    { key: "city", placeholder: "miasto", type: "text" },
    { key: "street", placeholder: "ulica", type: "text" },
    { key: "zipCode", placeholder: "kod pocztowy", type: "text" },
    { key: "houseNumber", placeholder: "numer domu", type: "text" },
    { key: "other", placeholder: "dodatkowe informacje do wysyłki", type: "text" },
]
export const invoice = [
    { key: "company_name", placeholder: "nazwa firmy/imię i nazwisko", type: "text" },
    { key: "nip_number", placeholder: "NIP (dla firm)", type: "text" },
    { key: "country_invoice", placeholder: "kraj", type: "text" },
    { key: "city_invoice", placeholder: "miasto", type: "text" },
    { key: "street_invoice", placeholder: "ulica", type: "text" },
    { key: "zipCode_invoice", placeholder: "kod pocztowy", type: "text" },
    { key: "houseNumber_invoice", placeholder: "numer domu", type: "text" },
]
export const productPost = [
    { key: "name", placeholder: "nazwa", type: "text" },
    { key: "entry", placeholder: "wstęp", type: "text" },
    { key: "description", placeholder: "opis", type: "text" },
    { key: "composion", placeholder: "skład", type: "text" },
    { key: "howToUse", placeholder: "jak używać", type: "text" },
    { key: "price", placeholder: "cena", type: "number" },
    { key: "weight", placeholder: "waga", type: "number" },
    { key: "category", placeholder: "kategoria", type: "text" },
    { key: "photo", placeholder: "zdjęcie", type: "file" },
]