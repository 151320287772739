import styled from 'styled-components'
export const ButtonHolder = styled.div`
    float: right;
    width: min-content;
`
export const MobileBr = styled.br`
    @media (min-width: 850px){display: none}
`
export const Button = styled.button`
color: black;
border: 0;
background-color: rgb(211, 206, 200);
padding: 10px 25px;
cursor: pointer;
transition: 300ms;
text-align: center;
:hover ${this}{
    background-color: rgb(230, 226, 221);
    padding: 10px 35px;
}
:active{
    background-color: green;
    color: aliceblue;
}
/* width: 50%; */
margin: 10px;
font-size: 20px;
`
export const Comm = styled.p`
    text-align: center;
    font-size: 14px;
    margin: 10px;
    width: fit-content;
`
export const Holder = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`
export const Header = styled.p`
    text-align: center;
    font-size: 25px;
    margin: 20px;
`
export const CommWrapper = styled.div`
    margin: auto;
    width: fit-content;
    margin-bottom: 40px;
`
export const UnderlinedInput = styled.input`
    outline: none;
    border: 0;
    border-radius: 0;
    background-color: rgba(248, 246, 244, 0);
    border-bottom: solid black 1px;
    width: 450px;
    @media (max-width: 600px) {
        width: 80vw;
    }
    margin: 10px;
    ::placeholder {
        ////
       color: rgb(111, 97, 76);
    }
    transition: 200ms;

    :hover ${this}{
        padding-left: 10px;
        width: 442px;
        @media (max-width: 600px) {
            width: calc(80vw - 8px);
        }
    }
    font-size: 20px;
`
export const BackGround = styled.div`
    background-color: white;
    min-height: 100vh;
`
export const BackGroundShort = styled.div`
background-color: white;
padding-bottom: 70px;
`
export const SignUpForm = styled.form`
    width: max-content;
    margin: auto;
    padding-left: 10px;
    margin-top: 60px;
`
export const SubmitInput = styled.input`
    background-color: white;
    font-weight: 500;
    border: solid rgb(182, 171, 157) 1px;
    border-radius: 10px;
    padding: 10px;
    color: rgb(161, 148, 131);
    cursor: pointer;
    transition: 250ms;
    margin-bottom: 10px;
    :hover ${this}{
        background-color: #f5f1ee;
        padding-left: 20px;
        padding-right: 20px;
    }
    font-size: 20px;
    :disabled ${this}{
        cursor: auto;
        opacity: 0.5;
    }
    margin-top: 30px;
`
export const ErrorText = styled.p`
    color: red;
    font-size: 13px;
    margin-left: 20px;
`
export const Title = styled.h1`
    font-size: 30px;
    text-align: center;
    font-weight: 100;
`
export const ResponseLogBox = styled.p`
    font-size: 25px;
    text-align: center;
    color: rgba(116, 103, 86, 0.7);
`