
import { getItems } from "../router/products"
import { useState, useEffect } from "react"
import { Th, Td, addLink } from "../styles/admin"
import ProductsRow from "./ProductsRow"
import { Add } from '@material-ui/icons'
import Navbar from "./Navbar"
import { Link } from "react-router-dom"
const Products = () => {
    const [products, setProducts] = useState([])
    const [chatValue, setChatValue] = useState("komunikaty")
    const productsSetter = async () => {
        setProducts((await getItems()).data)
    }
    useEffect(() => { productsSetter() }, [])
    console.log(products)
    return (
        <div>
            <Navbar />
            <table style={{ margin: "auto" }}>
                <tbody>
                    <tr><Td colSpan={9}>{chatValue}</Td></tr>
                    <tr>
                        <Th>zdjęcie</Th>
                        <Th>nazwa</Th>
                        <Th>ilość</Th>
                        <Th>dodaj</Th>
                        <Th>kategoria</Th>
                        <Th>waga</Th>
                        <Th>cena</Th>
                        <Th></Th>
                        <Th></Th>
                    </tr>
                    {products.map(item => (<ProductsRow setChatValue={setChatValue} productsSetter={productsSetter} item={item} key={item._id} />))}
                    <tr>
                        <Td colSpan={9}>
                            <Link style={addLink} to={"add"}><Add style={{ marginTop: "10px" }} /></Link>
                        </Td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default Products