import styled from 'styled-components';
import backendAddress from '../scripts/backendAddress';
const Button = styled.button`
    padding: 10px;
    font-size: 20px;
    margin: 0 10px;
    margin-top: 4px;
    color: black;
    justify-content: center;
    background-color: rgb(211, 206, 200);
    cursor: pointer;
    transition: 300ms;
    &:hover ${this}{
        background-color: rgb(230, 226, 221);
    }
    border: none;
    :active{
        background-color: green;
        color: aliceblue;
    }
    width: 80%;
    max-width: 250px;
`
const Message = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    width: 600px;
        max-width: 80vw;
        padding: 10px;
        background-color: rgb(248, 246, 244);
        border: solid rgb(180, 171, 165) 1px;
        color: rgb(158, 143, 122);
        text-align: center;
        z-index: 2;
    box-shadow: 2px 2px 2px 1px rgba(49, 49, 49, 0.2);
`
const A = styled.a`
    color: rgb(102, 90, 74);
`
const Text = styled.p`
    
`
const MsgBackground = styled.div`
    z-index: 3;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
`
const CookiesAllow = (props) => {
    const {setCookiesAllowed} = props
    return (
        <MsgBackground>
            <Message>
                <Text>
                    Korzystanie ze strony oznacza wyrażenie zgody na wykorzystywanie plików cookies <A href={`${backendAddress}/app//src//static//rodo.pdf`}>czytaj więcej...</A>
                </Text>
                <Button onClick = {()=>{localStorage.setItem("allowCookies", "necessary"); setCookiesAllowed(true)}}>zaakceptuj niezbędne</Button>
                <Button onClick = {()=>{localStorage.setItem("allowCookies", "all"); setCookiesAllowed(true)}}>zgadzam się</Button>
            </Message>
        </MsgBackground>
    )
}
export default CookiesAllow