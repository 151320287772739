import styled from "styled-components";
import { Link } from "react-router-dom";
export const Container = styled.div`
    width: 80vw;
    margin: auto;
    display: flex;
    /* flex-direction: row; */
    @media (max-width: 850px) {
        flex-direction: column;
    } 
`
export const SlideContainer = styled.div`
    width: fit-content;
    margin: 20px 10px 0px 10px;
    position: relative;    
    width: calc(100% - 20px);

`
export const LeftArrow = styled.div`
    position: absolute;
    :hover ${this}{
        background-color: white;
    }
    font-size: 40px;
    top: 50%;
    margin-top: -50px;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    opacity: 0.3;
    transition: 200ms;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
`
export const RightArrow = styled.div`
    position: absolute;
    right: 0;
    :hover ${this}{
        background-color: white;
    }
    font-size: 40px;
    top: 50%;
    margin-top: -50px;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    opacity: 0.3;
    transition: 200ms;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

`
export const DescContainer = styled.div`
    width: 80vw;
    margin: auto;
`
export const Image = styled.img`
    width: 100%;
    margin: 0;
`
export const Title = styled.h1`
    /* color: rgb(158, 143, 122); */
    font-size: 25px;
    font-weight: 500;
`
export const DescriptionHolder = styled.div`
    @media (min-width: 850px) {
        width: calc(40vw - 40px);
    } 
    min-height: calc(40vw - 40px);
    margin: 20px 10px 10px 10px;
    /* color: rgb(158, 143, 122); */
    border-radius: 10px;
    padding: 10px;
    padding-bottom: -20px;
`
export const Price = styled.div`
    /* color: rgb(158, 143, 122); */
    font-size: 20px;
    font-weight: 500;
`
export const Button = styled.button`
    color: black;
    border: 0;
    ${elem => elem.disabled ? "text-decoration: line-through;" : null}
    background-color: rgb(211, 206, 200);
    padding: 10px;
    cursor: pointer;
    transition: 300ms;
    text-align: center;
    :hover ${this}{
        background-color: rgb(230, 226, 221);
    }
    :active{
        background-color: ${elem => elem.disabled ? "red" : "green"};
        color: aliceblue;
    }
    width: 50%;
    margin: 10px;
    font-size: 20px;
`
export const Description = styled.div`
    /* color: rgb(158, 143, 122); */
    font-size: 20px;
`
export const Weight = styled.div`
    color: rgb(158, 143, 122);
    font-size: 18px;
`
export const NumberSelector = styled.div` 
    background-color: rgb(211, 206, 200);
    padding: 10px;
    /* color: rgb(158, 143, 122); */
    width: max-content;
    height: min-content;
    margin: 10px;
    font-size: 20px;
`
export const CartSection = styled.div` 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const PlusMinus = styled.span` 
    font-size: 20px;
    cursor: pointer;
    margin: 10px;
`
export const DescriptionMenu = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`
export const MenuOption = styled.div` 
    font-size: 25px;
    border-bottom: solid;
    border-width: 3px;
    border-color: rgba(158, 143, 122, ${(props) => props.chosen ? "1" : "0.5"});
    transition: 300ms;
    cursor: pointer;
    padding: 10px;
    :hover ${this}{
        border-color: rgba(158, 143, 122, 1);
    }
`
export const Route = styled.div` 
    color: rgb(158, 143, 122);
    font-size: 20px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: default;
`
export const MiniImage = styled.img`
    width: calc((100% - 60px)/3);
    margin: 10px;
    opacity: ${props => props.chosen ? "1" : "0.5"};
    transition: 300ms;
    cursor: pointer;
    :hover ${this}{
        opacity: 1;
    }
`
export const RouteLink = styled(Link)`
    text-decoration: none;
    color: rgb(158, 143, 122); 
    font-size: 20px;
`