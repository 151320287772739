import Navbar from '../components/Navbar'
import { BackGround, Title, Card, Text } from "../styles/account.jsx"
import Footer from '../components/Footer'
import { ButtonHolder  } from "../styles/signUp.jsx"
import ToShopButton from '../components/ToShopButton'
const Returns = () => {

    return(
        <>
            <BackGround>
                <Navbar />
                <ButtonHolder>
                    <ToShopButton />
                </ButtonHolder>
                <Card>
                    <Title>Dostawa i płatność</Title><br />
                    <Text>
                    Wysyłki są realizowane kurierem DPD oraz InPost Paczkomaty. <br />
                    Koszt każdej przesyłki wynosi 15 zł.<br />
                    Zwolnione z opłaty przewozowej są wysyłki w kwocie 250 zł i powyżej.<br />
                    <br />
                    Możliwe formy płatności:<br />
                    - rozliczenia transakcji kartą płatniczą i e-przelewem - przeprowadzane za pośrednictwem Przelewy24.pl<br />
                    - przelewem tradycyjnym. W tym przypadku KONIECZNE powiadom nas o dokonaniu płatności wysyłając maila na adres pracowniaiskry@gmail.com<br />
                    <br />
                    pracownia iskry Piotr Iskra<br />
                    13 1160 2202 0000 0005 7238 3020
                    </Text>
                </Card>
            </BackGround>
            <Footer/>
        </>
    )
}
export default Returns