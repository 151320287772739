
import { patchProduct, postProduct } from "../router/products"
import { productPost } from "../scripts/formsData"
import { useFormik } from "formik"
import { Form, Input, Submit, ErrorLog } from "../styles/productsForm"
import { useNavigate } from "react-router-dom"
import { productPatchSchema, productPostSchema } from "../scripts/productSchema"
import { useState } from "react"
const ProductsForm = (props) => {
    const navigate = useNavigate()
    const { product, getProduct } = props
    const [message, setMessage] = useState("")
    const onSubmit = async (values, actions) => {
        const res = product ? await patchProduct(values, product._id) : await postProduct(values)
        if (res.status === 200 && !product) {
            navigate("../qwerAdmin/products/" + res.data)
            getProduct(res.data)
        } else if (res.status === 200) {
            setMessage(res.data)
        } else console.log(res)
    }
    const { values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, setFieldTouched } = useFormik({
        initialValues: {
            name: product?.name || "",
            entry: product?.entry || "",
            description: product?.description || "",
            composion: product?.composion || "",
            howToUse: product?.howToUse || "",
            price: product?.price || "",
            weight: product?.weight || "",
            category: product?.category || "",
            photo: "",
            file: "",
        },
        validationSchema: product ? productPatchSchema : productPostSchema,
        onSubmit
    })
    return (<Form onSubmit={handleSubmit} >{
        productPost.map(field => {
            const file = (field.type === "file")
            return (
                <div key={field.key}>
                    <div>{field.placeholder} {file ? values.file ? "- wybrano plik" : "- wybierz plik" : null}</div>
                    {errors[file ? "file" : field.key] && touched[field.key] ? <ErrorLog>{errors[file ? "file" : field.key]}</ErrorLog> : null}
                    <Input
                        style={file ? { color: "transparent" } : {}}
                        name={field.key}
                        type={field.type}
                        value={file ? "" : values[field.key]}
                        onBlur={handleBlur}
                        onChange={file ? (e) => { setFieldValue("file", e.target.files[0]); setFieldTouched("photo") } : handleChange}
                    />
                </div>
            )
        })
    }
        <div>{message}</div>
        <Submit type="submit" />
    </Form>)
}
export default ProductsForm