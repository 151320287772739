import * as yup from "yup"
export const productPatchSchema = yup.object().shape({
    // photo: yup.string(),
    name: yup.string().max(50),
    entry: yup.string().required(),
    description: yup.string().required(),
    composion: yup.string().required(),
    howToUse: yup.string().required(),
    price: yup.number(),
    weight: yup.number(),
    category: yup.string(),
    file: yup.mixed(),
})
export const productPostSchema = yup.object().shape({
    // photo: yup.string(),
    name: yup.string().max(50).required(),
    entry: yup.string().required(),
    description: yup.string().required(),
    composion: yup.string().required(),
    howToUse: yup.string().required(),
    price: yup.number().required(),
    weight: yup.number().required(),
    category: yup.string().required(),
    file: yup.mixed().required(),
})