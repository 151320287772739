import { addToCart } from "../controller/cart";
import { LinkHolder, Holder, Image, Title, ButtonsHolder, PriceHolder, Button, Comm, LinkToProduct } from "../styles/product.jsx"
import backendAddress from "../scripts/backendAddress";
const App = (props) => {
    const { buttonTrigger, triggerValue, setCartView, product } = props
    const { _id, photo, name, price, number} = product
    return (
        <Holder key={_id}>
            <LinkToProduct to={`/products/${_id}`}>
            <Image src={backendAddress + "/" + photo.replaceAll("\\", "//")}/ >
            <Title>{name}</Title>
            </LinkToProduct>
            <ButtonsHolder>
                <PriceHolder>
                    {price.toFixed(2)} zł
                </PriceHolder>
                <Button onClick={async () => {
                    await addToCart(_id)
                    setCartView(true)
                    buttonTrigger(!triggerValue)
                }}
                    disabled = {!number}
                >
                    do koszyka
                </Button>
            </ButtonsHolder>

            {!number?<Comm>brak produktu w magazynie</Comm>:null}
            
            {number > 0 && number <= 5?<Comm>w magazynie tylko {number} szt.</Comm>:null}
            
        </Holder>
    );
};
export default App;