import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Product from '../components/Product';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import backendAddress from "../scripts/backendAddress";
import { RouteLink, Route, Container } from "../styles/productDetails";
const Message = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
        max-width: 80vw;
        padding: 10px;
        background-color: rgb(248, 246, 244);
        border: solid rgb(180, 171, 165) 1px;
        color: rgb(158, 143, 122);
        text-align: center;
        z-index: 2;
    box-shadow: 2px 2px 2px 1px rgba(49, 49, 49, 0.2);
`
const MsgBackground = styled(Link)`
    z-index: 3;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
`
const ProductsArea = styled.div`
width: 100vw;
min-height: calc(100vh - 190px);
margin: auto;
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
margin-top: calc(80vw / 6 - 12.5vw);
max-width: 1200px;
`
const App = () => {
    const detailsJSON = (useParams()).details
    const { category, searches } = detailsJSON ? JSON.parse(detailsJSON) : {}

    const [addToCartTrigger, addToCartTriggerFunc] = useState(true)
    const [products, setProducts] = useState([])
    const [cartView, setCartView] = useState(false)

    const productsToRender = products.filter(product =>
        (product.category === category || !category) &&
        (searches === product.name || product.tags.filter(tag => searches === tag).length > 0 || !searches) 
        // &&
        // product.number > 0
    )
    useEffect(() => {
        axios.get(backendAddress + "/products/").then(res => {
            setProducts(res.data)
        })
    }, [])

    const message = (useParams()).message

    useEffect(()=>{
        addToCartTriggerFunc()
    }, [message])
    return (
        <div>
            {
                message ?
                <MsgBackground to="/">
                    <Message>{decodeURI(message)}</Message>
                </MsgBackground>
                :null
            }
            <Navbar addToCartTrigger={addToCartTrigger} cartView={cartView} setCartView={setCartView}/>
            <Container>
                <div>
                <Route>
                <RouteLink 
                to="/"
                >
                    strona główna 
                </RouteLink>  {category?"/ "+category:null} 
                </Route>
                </div>
            </Container>
            <ProductsArea>
                
                {
                    productsToRender.map(product => (
                        <Product setCartView={setCartView} product={product} key={product._id} buttonTrigger={addToCartTriggerFunc} triggerValue={addToCartTrigger} />
                        
                    ))
                }
            </ProductsArea>
            <Footer/>
        </div>
    );
};

export default App;