import { useParams } from "react-router-dom"
import { Image, Remover, ImagesHolder, InputFile } from "../styles/shipping"
import { addPhoto } from "../router/products"
import { Delete } from '@material-ui/icons'
import { removePhoto } from "../router/products"
const ChangeImages = (props) => {
    const { product, getProduct } = props
    const { id, } = useParams()
    return <ImagesHolder>
        zdjęcia:
        {product.gallery.map((photo, index) => <Remover key={index} onClick={async () => { await removePhoto(id, index); getProduct(id) }}>
            <Image src={localStorage.getItem("backendURL") + "/" + photo.replaceAll("\\", "//")} />
            <Delete />
        </Remover>)}
        <InputFile type="file" onChange={async (e) => { await addPhoto(e.target.files[0], id); getProduct(id) }} />
    </ImagesHolder>
}
export default ChangeImages