import ItemsView from "./ItemsView"
import { useState, useEffect } from 'react'
import { getItem } from '../router/products'
import { OrderBackground, Text, TextRight } from "../styles/account"
const OrderView = (props) => {
    const {order} = props
    const [itemsToShow, setItemsToShow] = useState([])
    const itemsToShowSetter = async () => {
        if(!order.cart[order.cart.length - 1].quantity )
            order.cart.pop()
        setItemsToShow(await Promise.all(order.cart.map(async product => {
            const item = await getItem(product._id)
            return { ...item.data, number: product.quantity }
        })))
    }
    useEffect(() => { itemsToShowSetter() }, [])

    const price = itemsToShow.length ? itemsToShow.map(item => item.name ? item.price * item.number : 0).reduce((total, item) => total + item) : 0
    const time = new Date(order.create_time)
    return(
        <OrderBackground>
            <Text>
                {time.getDate()}.{(time.getMonth()+1).toString().padStart(2, '0')}.{time.getFullYear()} - {time.getHours().toString().padStart(2, '0')}:{time.getMinutes().toString().padStart(2, '0')}
            </Text>
            <ItemsView itemsToShow={itemsToShow}/>
            <TextRight>łącznie: {price.toFixed(2)}zł</TextRight>
        </OrderBackground>
    )
}
export default OrderView