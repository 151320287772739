import styled from "styled-components"
export const Holder = styled.div`
        border-bottom: solid rgb(158, 143, 122) 1px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: default;
    `
export const Image = styled.img`
        height: 60px;
        width: 60px;
        background-color: rgb(211, 206, 200);
    `
export const Title = styled.div`
        ${item => item.isOk ? null : "text-decoration: line-through;"}
        font-size: 15px;
        text-align: center;
        color: ${item => item.isOk ? "rgb(158, 143, 122)" : "red"};
    `
export const PriceHolder = styled.div`
        font-size: 15px;
        text-align: center;
        color: rgb(158, 143, 122);

    `
export const OperatorHolder = styled.span`
        color: rgb(158, 143, 122);
        border-radius: 15px;
        transition: 300ms;
        cursor: pointer;
        &:hover ${this}{
            color: rgb(98, 83, 62);
        }
    `
export const Operator = {
    fontSize: "20px",
    padding: "5px",
    borderRadius: "100%",

}