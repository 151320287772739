import { getToken } from '../scripts/storage';
import axios from "axios"
import backendAddress from '../scripts/backendAddress';
export const addToCart = (productID, number) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'POST', 
            url: backendAddress + "/carts",
            headers: { 
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { product: productID, number },
        };

        try {
            let response = await axios.request(options)
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
}

export const patchNumberOfProduct = async (id, number) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'PATCH',
            url: backendAddress + `/carts`,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { product: id, number }
        };

        try {
            let response = await axios.request(options)
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
}

export const resetCart = async (userId) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: backendAddress + `/carts/all`,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            }
        };

        try {
            let response = await axios.request(options)
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
}