import styled from "styled-components"
import { Link } from "react-router-dom";
export const Image = styled.img`
        margin: auto;
        height: 300px;
        width: 300px;
        margin: 20px;
        margin-bottom: 10px;
        cursor: pointer;
        padding-top: 5px;
`
export const LinkToProduct = styled(Link)`
        text-decoration: none;
        position: relative;
        transition: 400ms;
        &:hover ${this}{
            opacity: 0.8;
        }
`
export const Comm = styled.p`
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
`
export const LinkHolder = styled.div`
    
`
export const Holder = styled.div`
        height: 500px;
        width: 340px;
    `
export const Title = styled.div`
        font-size: 25px;
        text-align: center;
        margin-bottom: 10px;
        text-decoration: none;
        color: black;
    `
export const ButtonsHolder = styled.div`
        display: flex;
        margin: auto;
        width: fit-content;
    `
export const Button = styled.button`
        ${elem => elem.disabled ? "text-decoration: line-through;" : null}
        padding: 10px;
        font-size: 20px;
        margin: 0 10px;
        margin-top: 4px;
        color: black;
        justify-content: center;
        background-color: rgb(211, 206, 200);
        cursor: pointer;
        transition: 300ms;
        &:hover ${this}{
            background-color: rgb(230, 226, 221);
        }
        border: none;
        :active{
            background-color: ${elem => elem.disabled ? "red" : "green"};
            color: aliceblue;
        }
    
    `
export const PriceHolder = styled.div`
padding: 10px;
font-size: 25px;
margin: 0 10px;
justify-content: center;
`