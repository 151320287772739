import Navbar from '../components/Navbar'
import { useFormik } from 'formik'
import { shipping as fields, invoice as invoiceFields } from "../scripts/formsData"
import { order, orderForLogged, shipMethods as shipMethodsReq } from "../router/orders"
import { useState, useEffect } from 'react'
import { ResponseLogBox, Title, SubmitInput, SignUpForm, BackGround, Comm, CommWrapper } from "../styles/signUp.jsx"
import { ShipMethodsWrapper, ShipMethodWrapper, Radio, ShipMethod, TextHolder, InvoiceHolder, A, Center } from '../styles/shipping'
import InputText from '../components/InputText'
import { shippingSchema } from '../scripts/shippingSchema'
import ItemsView from '../components/ItemsView.jsx'
import { useParams } from 'react-router-dom'
import { getItem } from '../router/products'
import Footer from '../components/Footer.jsx'
import {LinkText } from '../styles/shipping'
import { getToken } from "../scripts/storage"
import { getUser } from '../router/user.js'
import { ButtonHolder  } from "../styles/signUp.jsx"
import ToShopButton from '../components/ToShopButton'
import backendAddress from '../scripts/backendAddress.js'
const SignUp = () => {
    const products = JSON.parse(useParams().products)
    const token = getToken() 
    const [itemsToShow, setItemsToShow] = useState([])
    const itemsToShowSetter = async () => {
        setItemsToShow(await Promise.all(products.map(async product => {
            const item = await getItem(product.id)
            return { ...item.data, number: product.number }
        })))
    }
    useEffect(async () => { 
        itemsToShowSetter(); 
        try{
            const user = await getUser()
            setFieldValue("name", user.name)
            setFieldValue("surname", user.surname)
            setFieldValue("email", user.email)
            setFieldValue("phone", user.phone)
            setFieldValue("country", user.address.country)
            setFieldValue("city", user.address.city)
            setFieldValue("street", user.address.street)
            setFieldValue("zipCode", user.address.zipCode)
            setFieldValue("houseNumber", user.address.houseNumber)
        }catch(e){
            console.log(e.message)
        }
    }, [])
    const price = itemsToShow.length ? itemsToShow.map(item => item.name ? item.price * item.number : 0).reduce((total, item) => total + item) : 0
    
    const [disabled, setDisabled] = useState(false)
    const [invoice, setInvoice] = useState(false)
    const [response, setResponse] = useState(null)
    const [accepted, setAccept] = useState(false)
    const [shipType, setShipType] = useState(0)
    const [shipMethods, setShipMethods] = useState([])


    const onSubmit = async (values, actions) => {
        setDisabled(true)
        let response
        try{
            response = await orderForLogged({ ...values, products, shipType, invoice }, actions)
            setResponse()
        }catch(e){
            response = await order({ ...values, products, shipType, invoice }, actions) 
        }
        if (response.error) setDisabled(false)
        setResponse(response)
    }
    const loadShipMethods = async () => {
        let res = await shipMethodsReq()
        setShipMethods(res.data.data)
    }
    if(!shipMethods.length)loadShipMethods()
    const { values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
        initialValues: {
            name: "",
            surname: "",
            email: "",
            phone: "",
            country: "",
            city: "",
            street: "",
            zipCode: "",
            houseNumber: "",
            other: "",
            nip_number: "",
            company_name: "",
            country_invoice: "",
            city_invoice: "",
            street_invoice: "",
            zipCode_invoice: "",
            houseNumber_invoice: "",
        },
        validationSchema: shippingSchema,
        onSubmit
    })
    return (
        <BackGround>
            <Navbar />
            <ButtonHolder>
                <ToShopButton />
            </ButtonHolder>
            <CommWrapper>
                {!token?<Comm>•Jeżeli masz już konto, <LinkText to="/Login">kliknij aby się zalogować</LinkText></Comm>:null}
                {price<200 ? <Comm>•Do darmowej wysyłki brakuje jeszcze {(200 - price).toFixed(2)}zł <br/>
                
                </Comm>:null}
            </CommWrapper>
            <Title>Podsumowanie</Title>
            <ItemsView itemsToShow={itemsToShow}/>
            <SignUpForm onSubmit={handleSubmit}>
                
                
                <Title>Dane do wysyłki</Title>
                <Center>
                {
                    fields.map(field => 
                        <InputText
                            key={field.key}
                            field={field}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                    )
                }
                <br/>
                <InvoiceHolder>
                    <Radio 
                        type="checkbox" 
                        checked={invoice}
                        onClick={()=>{setInvoice(!invoice)}}
                    />
                    <ShipMethod>faktura?</ShipMethod>
                </InvoiceHolder>
                {
                    invoice ? <> <br/><Title>Dane do faktury</Title>{invoiceFields.map(field => 
                        <InputText
                            key={field.key}
                            field={field}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                    )}</> : null
                }</Center>
                <br />
                <Title>Dostawa</Title>
                
                <ShipMethodsWrapper>
                    {shipMethods ? shipMethods.map((item, index) => 
                    <ShipMethodWrapper>
                            <Radio 
                                type="radio" 
                                id={index} 
                                name="ship_method" 
                                value={item} 
                                checked={shipType === index}
                                onClick={()=>{setShipType(index)}}
                            />
                            <ShipMethod for={index}>{item.name} : {(price < 200 ? item.price : 0).toFixed(2)}zł</ShipMethod>
                    </ShipMethodWrapper>):null}
                </ShipMethodsWrapper>
                <TextHolder>
                    <Radio type="checkbox" id="accept" checked={accepted} onClick={()=>{setAccept(!accepted)}}/>
                    <ShipMethod for="accept">Przeczytałem/am i akceptuję <A href={`${backendAddress}/app//src//static//regulamin.pdf`}>regulamin sprzedaży</A> i <A href={`${backendAddress}/app//src//static//rodo.pdf`}>politykę prywatności</A></ShipMethod>
                </TextHolder>
                <br />
                {response ? <ResponseLogBox style={response.error ? { color: "red" } : {}}>{response.log}</ResponseLogBox> : null}
                <SubmitInput
                    type='submit'
                    value='przejdź do płatności'
                    disabled={disabled || !accepted}
                />
            </SignUpForm>
            <Footer/>
        </BackGround >
    )
}

export default SignUp