

import { Image } from "../styles/shipping"
import { Td, Tr, InputNumber } from "../styles/admin"
import { DeleteOutline, Settings, AddCircleOutline } from '@material-ui/icons'
import { useState } from "react"
import { patchNumber as patchNumberReq } from "../router/products"
import { Link } from "react-router-dom"
import { deleteItem } from "../router/products"
const ProductsRow = (props) => {
    const { item, productsSetter, setChatValue } = props
    const [numberToAdd, setNumberToAdd] = useState(0)
    const patchNumber = async () => {
        setChatValue("ładowanie...")
        const response = await patchNumberReq(numberToAdd, item._id)
        setChatValue(response.data)
        productsSetter()
        setNumberToAdd(0)
    }
    return (
        <Tr >
            <Td><Image src={localStorage.getItem("backendURL") + "/" + item.photo.replaceAll("\\", "//")} /></Td>
            <Td>{item.name}</Td>
            <Td>{item.number}</Td>
            <Td>
                <InputNumber type="number" value={numberToAdd} onChange={e => { setNumberToAdd(e.target.value) }} />
                <AddCircleOutline onClick={patchNumber} style={{ marginTop: "10px", marginBottom: "-10px", cursor: "pointer" }} />
            </Td>
            <Td>{item.category}</Td>
            <Td>{item.weight}</Td>
            <Td>{(item.price).toFixed(2)}zł</Td>
            <Td
                onClick={async () => { const res = await deleteItem(item._id); productsSetter(); setChatValue(res.data) }}
                style={{ cursor: "pointer" }}
            ><DeleteOutline /></Td>
            <Td><Link style={{ cursor: "pointer", color: "black" }} to={`/qwerAdmin/products/${item._id}`}><Settings /></Link></Td>
        </Tr>
    )
}
export default ProductsRow