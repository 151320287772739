import { useParams } from "react-router-dom"
import { Remover, ImagesHolder, InputText } from "../styles/shipping"
import { addTag, removeTag } from "../router/products"
import { Delete } from '@material-ui/icons'
const ChangeImages = (props) => {
    const { product, getProduct } = props
    const { id, } = useParams()
    return <ImagesHolder>
        tagi:
        {product.tags.map((tag, index) => <Remover key={index} onClick={async () => { await removeTag(id, index); getProduct(id) }}>
            {tag}
            <Delete />
        </Remover>)}
        <form onSubmit={async (e) => {
            e.preventDefault();
            await addTag(e.target[0].value, id);
            getProduct(id)
        }}
        ><InputText type="text" /><input type="submit" value="dodaj tag"/></form>
    </ImagesHolder>
}
export default ChangeImages