
import Cart from '../pages/Cart';
import { useState, useEffect } from 'react'
import { getCart } from '../controller/cart';
import backendAddress from "../scripts/backendAddress";
import { ShortcutIcon, ShortcutHolder, Notification, CartHolder, CartBackground, } from "../styles/menu.jsx"
const CartShortcut = (props) => {
    const { addToCartTrigger, setUser, logOut, mobileMenuType, openMenu, cartView, setCartView } = props
    const [cart, setCart] = useState([])
    const [changNumberTrigger, setChangeNumberTrigger] = useState(true)
    let totalNumberOfItems = cart.length > 0 ? cart.map(item => item.name ? item.number: 0).reduce((total, number) => total + number) : 0
    const changeCart = async () => {
        let {cart, user} = await getCart()
        setCart(cart)
        setUser(user)
    }
    useEffect(() => {
        changeCart()
    }, [addToCartTrigger, changNumberTrigger, logOut])
    const cartImg = backendAddress + 
    `/app//src//static//${mobileMenuType === "cart" ? "cancel" : "cartIcon"}.png`
    
    return (
        <ShortcutHolder >
            {
                    cartView ?
                <CartBackground>
                    <CartHolder>
                        <Cart
                        changNumberTrigger={changNumberTrigger}
                        setChangeNumberTrigger={setChangeNumberTrigger}
                        cart={cart}
                        isEmpty={(totalNumberOfItems <= 0)}
                        isClosable={true}
                        setCartView={setCartView}
                        />
                    </CartHolder>
                </CartBackground>
                :null
            }
            {totalNumberOfItems > 0 ? <Notification>{totalNumberOfItems}</Notification> : null}
            <ShortcutIcon src={cartImg} onClick={() =>{openMenu("cart")}}/>
                {mobileMenuType === "cart" ? <Cart
                    changNumberTrigger={changNumberTrigger}
                    setChangeNumberTrigger={setChangeNumberTrigger}
                    cart={cart}
                    isEmpty={(totalNumberOfItems <= 0)}
                ></Cart> : null}
            

        </ShortcutHolder>
    )
}
export default CartShortcut