import styled from "styled-components"
import { Link } from "react-router-dom"
export const RegularMenuWrapper = styled.div`
    float: right;
    width: calc(100% - 175px);
    height: 100%;
    @media (max-width: 850px){
        display: none;
    }
    
`
export const MobileMenuWrapper = styled.div`
    @media (min-width: 850px){
        display: none;
    }
`
export const CartBackground = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
`
export const CartHolder = styled.div`
    position: fixed;
    top: 20vh;
    left: 50vw;
    width: fit-content;
    height: fit-content;
    @media (min-width: 850px){    
        left: calc(50vw + 115px);
    }
`
export const ShortcutIconSearch = styled.img`
    cursor: pointer;
    padding: 2px;
    height: 30px;
`
export const SearchField = styled.div`
    margin-right: 20px;
    float: right;
`


export const TagView = styled(Link)`
    display: block;
    text-decoration: none;
    font-size: 20px;
    color: rgb(158, 143, 122);
    padding: 8px;
    margin: 0;
    transition: 300ms;
    &:hover ${this} {
        padding-left: 15px;
    }
`
export const SearchBar = styled.div`
    height: min-content;
    width: min-content;
    border-bottom: solid black;
    border-width: 1px;
    display: flex;
    padding: 0 5px ;
    padding-top: 5px;
    transition: 800ms;
    @media (min-width: 850px){
        float: right;
    }
    
    margin-right: 10px;
    border-radius:  5px 5px 0 0;
    :hover ${this}{
        background-color: rgba(255,255,255,0.3);
    }
   
`
export const TagsView = styled.div`
    width: 280px;
    max-height: 220px;
    padding: 5px;
    background-color: white;
    position: absolute;
    margin-top: 50px;
    margin-left: -5px;
    background-color: rgb(248, 246, 244);
    border: solid rgb(180, 171, 165) 1px;
    border-radius:  0 0 5px 5px;
    overflow:auto;
    z-index: 2;
`
export const SearchBarInput = styled.input`
    outline: none;
    border: 0;
    margin-left: 0 5px;
    background-color: rgba(0,0,0,0);
    font-size: 20px;
`
export const ShortcutIcon = styled.img`
    @media (min-width: 850px){
        transition: 200ms;
    }
    cursor: pointer;
    padding: 0 5px;
    height: 40px;
`

export const Notification = styled.div`
    padding: 2px;
    width: 20px;
    height: 20px;
    font-size: 13px;
    border-radius: 12px;
    color: white;
    background-color: ${p => p.color ? p.color : "#a3591c"};
    text-align: center;
    position: absolute;
    margin-top: -6px;
    margin-left: 31px;
    transition: 500ms;
`
export const ShortcutHolder = styled.button`
    border: none;
    background-color: rgba(0,0,0,0);
    border-radius: 5px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    float: right;
    cursor: pointer;
    
    @media (min-width: 850px){
        ${p => !p.focus ? `
    &:hover ${ShortcutIcon} {
        margin-top: -5px;
        padding-bottom: 5px;
    }
    &:hover ${Notification} {
        transform: translate(0, -5px);
    }
    ` : null}
    }
    
`
export const Filters = styled.div`
    float: right;
    margin-right: 20px;
    background-color: rgb(248, 246, 244);
    border-radius: 5px;
    padding-left: 10px;
    color: black;
    height: 45px;
    cursor: pointer;
    transition: 500ms;
    &:hover ${this} {
    background-color: white;
    }
`
export const Wrapper = styled.div`
    padding-top: 20px;
    height: calc(50% - 20px);
    width: 100%;
    @media (max-width: 850px){
        display: flex;
    }
    justify-content: center;
    
`