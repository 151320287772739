
import { getToken } from '../scripts/storage';
import axios from "axios"
import backendAddress from '../scripts/backendAddress';
export const getItem = async (productID) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'GET',
            url: backendAddress + `/products/${productID}`,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
            },
        };

        try {
            let response = await axios.request(options)
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
}
export const getItems = async (productID) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'GET',
            url: backendAddress + `/products/`,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
            },
        };

        try {
            let response = await axios.request(options)
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
}
export const patchNumber = async (number, id) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'PATCH',
            url: backendAddress + "/products/number",
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { productId: id, action: "+", number },
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}
export const deleteItem = async (id) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: backendAddress + "/products/" + id,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}
export const patchProduct = async (values, id) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'PATCH',
            url: backendAddress + "/products",
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { id, ...values },
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}
export const postProduct = async (values) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'POST',
            url: backendAddress + "/products",
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: values,
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}
export const addPhoto = async (image, id) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'POST',
            url: backendAddress + "/products/photo",
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { file: image, productId: id },
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}
export const removePhoto = async (productId, index) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: backendAddress + `/products`,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { productId, index },
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}
export const addTag = async (tag, productId) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'POST',
            url: backendAddress + `/products/tag`,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { productId, tag },
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}
export const removeTag = async (productId, index) => {
    return new Promise(async (resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: backendAddress + `/products/tag`,
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': `Bearer ${getToken()}`
            },
            data: { productId, index },
        };

        try {
            let response = await axios.request(options)
            resolve(response)
        } catch (error) {
            resolve(error.message)
        }
    })
}