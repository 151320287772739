
import backendAddress from "../scripts/backendAddress";
import { Container, Reference, AReference, AReferenceUnderline, Right } from "../styles/footer"
const Footer = () =>{
    return(
        <Container>
            <Right>
                Znajdź nas na <AReferenceUnderline  href={"https://www.facebook.com/profile.php?id=100064580537058"}>facebooku</AReferenceUnderline> i <AReferenceUnderline  href={"https://www.instagram.com/pracownia_iskry/"}>instagramie</AReferenceUnderline>
            </Right>
            {/* <AReferenceRight href={"https://www.facebook.com/profile.php?id=100064580537058"}><img src={facebook} alt="instagram" width="32"/></AReferenceRight> */}
            <Reference to={`/contact`}>Kontakt</Reference><br />
            <Reference to={`/account`}>Moje konto</Reference><br />
            <Reference to={`/shippings`}>Dostawa i płatność</Reference><br />
            <AReference href={`${backendAddress}/app//src//static//regulamin.pdf`}>Regulamin sprzedaży</AReference><br />
            <AReference href={`${backendAddress}/app//src//static//rodo.pdf`}>Polityka prywatności</AReference><br />
            <Reference to={`/returns`}>Reklamacje i zwroty</Reference><br />
        </Container>
    )
}
export default Footer