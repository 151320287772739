import styled from "styled-components"
export const Logo = styled.img`
    /* width: 160px; */
    /* width: 176px; */
    height: 176px;
    @media (min-width: 850px){
    float: left;}
    margin: 0 25px;
    margin-top: -5px;
`
export const ShortWrapper = styled.div`
    float: right;
    width: min-content;
`
export const Wrapper = styled.div`
    background-color: rgb(211, 206, 200);
    @media (min-width: 850px){
        display: none;
    }
`
export const Holder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 20px;
`
export const Container = styled.div`
    padding: 15px;
    height: 160px;
    width: calc(100%-30px);
    background-color: rgb(211, 206, 200);
    @media (max-width: 850px){
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
`
export const CategoriesShortcut = styled.button`
    font-size: 25px;
    border: none;
    background-color: rgba(0,0,0,0);
    float: right;
    margin-top: 10px;
    cursor: pointer;
    transition: 300ms;
    margin-left: 10px;
    :hover{
        padding-right: 14px;
        margin-left: 0;
    }
`
export const CategoriesBG = styled.div`
    transform: translate(calc(100px - 100%), 50px);
    width: max-content;
    position: absolute;
    z-index: 2;
`