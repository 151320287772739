
import { Wrapper } from "../styles/menu.jsx"
import AccountShortcut from './AccountShortcut';
import CartShortcut from './CartShortcut';
import Searcher from './Searcher';
import { useState } from 'react';

const InputText = (props) => {
    const [logOut, setLogOut] = useState(false) 
    const { user, setUser, setAccountFocus, accountFocus, mouseOnAccountMenu, setMouseOnAccountMenu, cartView, setCartView } = props
    return (
        <Wrapper>
            <CartShortcut 
            addToCartTrigger={props.addToCartTrigger} 
            cartView={cartView} 
            setCartView={setCartView}
            user={user}
            setUser={setUser}
            logOut={logOut}
            />
            <AccountShortcut 
            setLogOut={setLogOut}
            logOut={logOut}
            user={user}
            mouseOnMenu={mouseOnAccountMenu}
            setMouseOnMenu={setMouseOnAccountMenu}
            accountFocus={accountFocus}
            setAccountFocus={setAccountFocus}
            />
            <Searcher  />
        </Wrapper>
    )
}
export default InputText