import Navbar from '../components/Navbar'
import { BackGround, Title, Card, Header, Value } from "../styles/account.jsx"
import Footer from '../components/Footer'
const Account = () => {

    return(
        <>
            <BackGround>
                <Navbar />
                <Card>
                    <Title>Kontakt</Title><br />
                    <Header>Nazwa firmy</Header>
                    <Value to="/">pracownia iskry Piotr Iskra</Value><br />
                    <Header>Adres</Header>
                    <Value to="https://maps.app.goo.gl/Km9KvrdhwN5hNg7WA">32-445 Krzyszkowice 629</Value><br />
                    <Header>Adres mailowy</Header>
                    <Value to="mailto:pracowniaiskry@gmail.com">pracowniaiskry@gmail.com</Value><br />
                    <Header>Numer telefonu</Header>
                    <Value to="tel:+48604146211">+48 604 146 538</Value><br />
                    <Header>Numer konta</Header>
                    <Value>13 1160 2202 0000 0005 7238 3020</Value><br />
        
                </Card>
            </BackGround>
            <Footer/>
        </>
    )
}
export default Account