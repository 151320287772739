import { useParams } from "react-router-dom";
import { addToCart } from "../controller/cart";
import { RouteLink, MiniImage, Route, DescContainer, DescriptionMenu, MenuOption, PlusMinus, CartSection, NumberSelector, Weight, Container, Image, Title, DescriptionHolder, Price, Button, Description, SlideContainer, LeftArrow, RightArrow } from "../styles/productDetails";
import { getItem } from "../router/products";
import { useEffect, useState } from "react";
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ProductsLine from "../components/ProductsLine"
import { Comm } from "../styles/product.jsx"
import backendAddress from "../scripts/backendAddress";
const App = (props) => {
    const [descStatus, setDescStatus] = useState(0)
    const [product, setProduct] = useState(null)
    const [cartNumber, setCartNumber] = useState(1)
    const [cartView, setCartView] = useState(false)
    const [photoID, setPhotoId] = useState(2)
    const { productID } = useParams()
    const loadItem = async () => {
        const response = await getItem(productID)
        setProduct(response.data)
        window.scrollTo(0, 0)
    }
    const [addToCartTrigger, addToCartTriggerFunc] = useState(true)
    useEffect(() => {
        loadItem()
    },[productID])

    return product ? (
        <div>
            <Navbar addToCartTrigger={addToCartTrigger} cartView={cartView} setCartView={setCartView}/>
            <Container>
                <div>
                <Route>
                <RouteLink 
                to="/"
                >
                    strona główna 
                </RouteLink> / <RouteLink 
                to={`/shop/${JSON.stringify({ category: product.category })}`}
                >
                    {product.category} 
                </RouteLink> / {product.name}
                </Route>
                </div>
            </Container>
            <Container key={product._id}>
                <div>
                    <SlideContainer>
                        <LeftArrow onClick={()=>setPhotoId(photoID === 0 ? 2 : photoID - 1)}>❮</LeftArrow>
                        <RightArrow onClick={()=>setPhotoId((photoID + 1 ) % 3)}>❯</RightArrow>
                        <Image src={backendAddress + "/" + (photoID === 2 ? product.photo : product.gallery[photoID]).replaceAll("\\", "//")}/>
                        
                    </SlideContainer>
                    <MiniImage 
                    src={backendAddress + "/" + product.photo.replaceAll("\\", "//")} 
                    onClick = {()=>{setPhotoId(2)}}
                    chosen = {photoID === 2}
                    />
                    {product.gallery.map((photo, index) => index < 2 ?
                        <MiniImage 
                        src={backendAddress + "/" + photo.replaceAll("\\", "//")} 
                        onClick = {()=>{setPhotoId(index)}}
                        chosen = {photoID === index}
                        />
                        :null
                    )}
                </div>
                <div>
                    <DescriptionHolder>

                        <Title>{product.name}</Title>
                        <Weight>{product.weight} g</Weight>
                        <Price>{product.price.toFixed(2)} zł</Price>
                        <Description>
                            {product.entry.split('##').map(fragment => <>{fragment}<br/></>)}
                        </Description>

                    </DescriptionHolder>
                    <CartSection>
                        <NumberSelector>
                            <PlusMinus onClick = {() => {
                                if(cartNumber > 0) setCartNumber(cartNumber-1)
                            }}>-</PlusMinus> {cartNumber} 
                            <PlusMinus onClick = {() => {setCartNumber(cartNumber+1)}}>+</PlusMinus>
                            
                            
                        </NumberSelector>
                        <Button onClick={async () => {
                                await addToCart(product._id, cartNumber)
                                setCartView(true)
                                addToCartTriggerFunc(!addToCartTrigger)
                            }}
                                disabled = {product.number < cartNumber}
                            >
                                do koszyka
                        </Button>
                        
                    </CartSection>
                    {!product.number?<Comm>brak produktu w magazynie</Comm>:null}
            
                        {product.number > 0 && (product.number <= 5 || product.number <= cartNumber)?<Comm>w magazynie tylko {product.number} szt.</Comm>:null}
                </div>
            </Container>
            <DescContainer>
                <DescriptionMenu>
                    <MenuOption
                        onClick = {()=>{setDescStatus(0)}} chosen = {descStatus === 0}
                    >opis</MenuOption>
                    <MenuOption
                        onClick = {()=>{setDescStatus(1)}} chosen = {descStatus === 1}
                    >skład</MenuOption>
                    <MenuOption
                        onClick = {()=>{setDescStatus(2)}} chosen = {descStatus === 2}
                    >jak używać</MenuOption>
                </DescriptionMenu>
                <Description>{
                    descStatus === 0?
                    product.description.split('##').map(fragment => <>{fragment}<br/></>):
                    descStatus === 1?
                    product.composion.split('##').map(fragment => <>{fragment}<br/></>) :
                    product.howToUse.split('##').map(fragment => <>{fragment}<br/></>)
                }</Description>
                <br />
                <Description>
                    Zobacz również:
                    <ProductsLine buttonTrigger={addToCartTriggerFunc} triggerValue={addToCartTrigger}/>
                </Description>
            </DescContainer>
            <Footer/>
        </div>
    ) : (
        <div>
            <Navbar />
            loading...
        </div>
    )
};
export default App;