import styled from "styled-components"
import { Link } from "react-router-dom"
import backendAddress from "../scripts/backendAddress"
const LinkBox = styled(Link)`
    opacity: 1;
    float: left;
    border: rgba(255, 255, 255, 0) solid 1px;
    margin-left: 20px;
    border-radius: 5px;
    transition: 500ms;
    &:hover ${this} {
    border-color: rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0 5px;
    }
    text-decoration: none;
    color: white;
`
const Container = styled.div`
    background-color: rgb(150, 150, 150);
    height: 50px;
    padding: 10px;
`
const Navbar = () => {
    const routes = ["login", "products", "orders"]
    return (<Container>{
        routes.map(name => <LinkBox key={name} to={`/qwerAdmin/${name}`}>{name}</LinkBox>)
    }
        <LinkBox to={`${backendAddress}/products/images`}>images</LinkBox>
    </Container>
    )
}
export default Navbar