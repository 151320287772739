import styled from "styled-components"
import { Link } from "react-router-dom"
export const Holder = styled.div`
        background-color: rgba(189, 183, 180, 0);
        border-radius: 5px;
        position: absolute;
        float: right;
        padding: 1px;
        width: 350px;
        margin-left: -304px;
        @media (max-width: 850px) {
            display: none;
        }
    `
export const LinkText = styled(Link)`
    text-decoration: none;
`
export const OptionHolder = styled.div`
    background-color: rgb(248, 246, 244);
    border: solid rgb(180, 171, 165) 1px;
    color: rgb(158, 143, 122);
    border-radius: 5px;
    cursor: pointer;
    transition: 200ms;
    margin: 1px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 20px;
    margin-left: 10px;
    &:hover ${this} {
        padding-right: 20px;
        background-color: white;
    }
    float: right;
    `