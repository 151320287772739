import styled from "styled-components"
import { Link } from "react-router-dom"
export const ProductsHolder = styled.table`
    margin-top: 20px;
    width: fit-content;
    margin: auto;
`
export const ProductHolder = styled.tr`
    
`
export const Center = styled.div`
    margin: auto;
    width: min-content;
`
export const A = styled.a`
    color: rgb(102, 90, 74);
`
export const TextHolder = styled.div`
    margin: auto;
    width: 100%;
    max-width: 80vw;
`
export const InvoiceHolder = styled.div`
    width: max-content;
    margin: auto;
`
export const ShipMethodWrapper = styled.div`
    width: max-content;
    margin: 0;
`
export const ShipMethodsWrapper = styled.div`
    width: max-content;
    margin: auto;
    margin-bottom: 30px;
`
export const ShipMethod = styled.span`
    font-size: 20px;
    color: rgb(150, 132, 107);
    /* max-width: 10vw; */
`
export const Radio = styled.input`
    accent-color: black;
    margin-right: 10px;
`

export const LinkText = styled(Link)`
    font-size: 16px;
    color: rgb(150, 132, 107);
    width: 100%;
`

export const BigLinkText = styled(Link)`
    font-size: 20px;
    text-decoration: none;
    color: rgb(150, 132, 107);
`
export const Image = styled.img`
    width: 50px;
`
export const Wrapper = styled.div`
    width: 80vw;
    margin: auto;
`
export const Td = styled.td`
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 20px;
    text-align: center;
    color: rgb(150, 132, 107);
`
export const Th = styled.th`
    margin: 5px;
    padding: 5px;
    font-size: 20px;
    color: rgb(150, 132, 107);
`
export const Remover = styled.div`
    margin: 10px;
    padding: 10px;
    background-color: #e9e9e9;
    border: solid #cccccc 2px;
    width: min-content;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
`
export const ImagesHolder = styled.div`
    display: flex;
    flex-direction: row;
`
export const InputFile = styled.input`
    margin: 10px;
    color: transparent;
    border-radius: 10px;
    border: solid #cccccc 2px;
    background-color: #e9e9e9;
    padding: 10px;
    width: 180px;
`
export const InputText = styled.input`
    margin: 10px;
    border-radius: 10px;
    border: solid #cccccc 2px;
    background-color: #e9e9e9;
    padding: 10px;
    width: 180px;
`