
import { getOrders } from "../router/orders"
import { useState, useEffect } from "react"
import Navbar from "./Navbar"
import OrderView from "./OrderView"
import { Td, Tr, Th, Table} from "../styles/admin"

const statusList = ["zamówione","opłacone","spakowane","wysłane"]
const Orders = () => {
    const [orders, setOrders] = useState([])
    const [chosen, setChosen] = useState(null)

    const orderSetter = async () => {
        setOrders((await getOrders()).data)
    }
    useEffect(() => { orderSetter() }, [])
    
    return (
        <div>
            <Navbar />
            <Table>
                <Tr>
                    <Th></Th>
                    <Th>Imię i nazwisko</Th>
                    <Th>data złożenia zamówienia</Th>
                    <Th>status zamówienia</Th>
                    <Th>numer zamówienia</Th>
                </Tr>
                {orders ? orders.map((order, index) => 
                <OrderView 
                    order={order} 
                    chosen={chosen} 
                    setChosen={setChosen} 
                    index={index}
                    statusList={statusList}
                    orderSetter={orderSetter}
                />):null}
            </Table>
        </div>
    )
}
export default Orders