import Navbar from '../components/Navbar'
import { useFormik } from 'formik'
import { loginReq } from "../router/user"
import { useState } from 'react'
import { ResponseLogBox, Title, SubmitInput, SignUpForm, BackGround, ButtonHolder, Comm, CommWrapper } from "../styles/signUp.jsx"
import InputText from '../components/InputText'
import ToShopButton from '../components/ToShopButton'
import Footer from '../components/Footer'
import {useNavigate} from "react-router-dom"
import {LinkText } from '../styles/shipping'
export const fields = [
    { key: "email", placeholder: "e-mail", type: "text" },
    { key: "password", placeholder: "hasło", type: "password" },
]
const Login = () => {
    
    const navigate = useNavigate()

    const [disabled, setDisabled] = useState(false)
    const [response, setResponse] = useState(null)
    if (response && !response.error) navigate("/message/zalogowano")
    const onSubmit = async (values, actions) => {
        setDisabled(true)
        setResponse(await loginReq(values, actions))
        setDisabled(false)
    }
    const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit
    })
    return (
        <>
            <BackGround>
                <Navbar />
                <ButtonHolder>
                    <ToShopButton />
                </ButtonHolder>
                <SignUpForm onSubmit={handleSubmit}>
                    <Title>Logowanie</Title>
                    {
                        fields.map(field => {
                            return (
                                <InputText
                                    key={field.key}
                                    field={field}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            )
                        })
                    }
                    {response ? <ResponseLogBox style={response.error ? { color: "red" } : {}}>{response.log}</ResponseLogBox> : null}
                    <SubmitInput
                        type='submit'
                        value='zaloguj się'
                        disabled={disabled}
                    />
                    <CommWrapper>
                        <Comm>•Jeżeli nie masz konta, <LinkText to="/SignUp">kliknij aby się zarejestrować</LinkText></Comm>
                        <Comm>•Nie pamiętasz hasła? <LinkText to="/ResetPassword">kliknij aby je zresetować</LinkText></Comm>
                        <br />
                        <br />

                    </CommWrapper>
                </SignUpForm>
            </BackGround>
            <Footer/>
        </>
    )
}

export default Login