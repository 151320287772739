import { patchNumberOfProduct } from "../controller/cart";
import { Holder, Image, Title, PriceHolder, Operator, OperatorHolder } from "../styles/productInCart.jsx"
import { Add, Remove } from '@material-ui/icons'
import backendAddress from "../scripts/backendAddress";
const ProductInCart = (props) => {
    const { number, name, photo, price, id, amount, changNumberTrigger, setChangeNumberTrigger } = props
        const isNumberOk = number <= amount
        return (
            <Holder>
                <Image src={backendAddress + "/" + photo.replaceAll("\\", "//")} />
                <div>
                    <Title isOk={isNumberOk}>{name}</Title>
                    <PriceHolder>
                        {(price * number).toFixed(2)} zł, {number} szt.
                    </PriceHolder>
                    {
                        isNumberOk ? null :
                        <PriceHolder>w magazynie: {amount} szt.</PriceHolder>
                    }
                </div>
                <div>
                    <OperatorHolder onClick={async () => { patchNumberOfProduct(id, number + 1, changNumberTrigger, setChangeNumberTrigger) }}>
                        <Add style={Operator} />
                    </OperatorHolder>
                    <OperatorHolder onClick={async () => { patchNumberOfProduct(id, number - 1, changNumberTrigger, setChangeNumberTrigger) }}>
                        <Remove style={Operator} />
                    </OperatorHolder>
                </div>
            </Holder>
        );
    // else return <Holder>produkt nieaktywny</Holder>
};
export default ProductInCart;